import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import { BlogPostsPage, BlogSeo } from 'modules/blog';
import type { BlogPost, BlogPostsPageContext, PostCategory } from 'modules/blog/types';
import { Layout } from 'modules/ui';

interface DataProps {
    allStrapiPost: {
        nodes: BlogPost[];
    };
    strapiCategory: PostCategory;
    allStrapiCategory: { nodes: any[] };
}

const BlogPostsCategory = ({ data, pageContext }: PageProps<DataProps, BlogPostsPageContext>) => (
    <>
        <BlogSeo title={`${data.strapiCategory.title} #${pageContext.currentPage}`} description="" />
        <Layout>
            <BlogPostsPage
                title={data.strapiCategory.title}
                posts={data.allStrapiPost.nodes}
                categories={data.allStrapiCategory.nodes}
                basePath={`/blog/category/${data.strapiCategory.slug}`}
                pageContext={pageContext}
                showAllCategory
            />
        </Layout>
    </>
);

export const pageQuery = graphql`
    query ($slug: String!, $skip: Int!, $limit: Int!, $localeRegex: String!) {
        allStrapiCategory(sort: { fields: title, order: ASC }, filter: { locale: { regex: $localeRegex } }) {
            nodes {
                slug
                title
                locale
            }
        }
        strapiCategory(slug: { eq: $slug }, locale: { regex: $localeRegex }) {
            ...PostCategory
        }
        allStrapiPost(
            filter: { categories: { elemMatch: { slug: { eq: $slug } } }, locale: { regex: $localeRegex } }
            sort: { fields: publishedAt, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                ...BlogPost
            }
        }
    }
`;

export default BlogPostsCategory;
